import request from '@/plugins/axios'

// 平台配置

// 获取平台信息
export const apiBaseConfig = () => request.get('/settings.platform/getBaseConfig')

// 设置平台信息
export const apiBaseConfigEdit = (params: any) => request.post('/settings.platform/setBaseConfig', params)

// 获取平台备案信息
export const apiRecordConfig = () => request.get('/settings.platform/getRecordConfig')

// 设置平台备案信息
export const apiRecordConfigEdit = (params: any) => request.post('/settings.platform/setRecordConfig', params)


// 系统设置

// 获取系统日志列表
export const apiSystemlogList = (params: any) => request.get('/settings.log/lists', {params})

// 清除系统缓存
export const apiSystemCacheClear = () => request.post('/settings.cache/clear')

// 定时任务列表
export const apiCrontabLists = () => request.get('/crontab.crontab/lists')

// 添加定时任务
export const apiCrontabAdd = (params: any) => request.post('/crontab.crontab/add', params)

// 查看详情
export const apiCrontabDetail = (params: any) => request.get('/crontab.crontab/detail', {params})

// 编辑定时任务
export const apiCrontabEdit = (params: any) => request.post('/crontab.crontab/edit', params)

// 删除定时任务
export const apiCrontabDel = (params: any) => request.post('/crontab.crontab/delete', params)

// 获取规则执行时间
export const apiCrontabExpression = (params: any) => request.get('/crontab.crontab/expression', {params})

// 操作定时任务
export const apiSrontabOperate = (params: any) => request.post('/crontab.crontab/operate', params)

/** E 系统维护 **/

/** S 系统更新 **/
// 系统更新列表
export const apiSystemUpgradeLists = (params: any) => request.get('/settings.upgrade/lists')

// 下载更新包
export const apiSystemUpgradeDownloadPkg = (params: any) => request.post('/settings.upgrade/downloadPkg', params)

// 一键更新
export const apiSystemUpgrade = (params: any) => request.post('/settings.upgrade/upgrade', params)
/** E 系统更新 **/

// 系统环境
export const apiSystemSystemEnv = () => request.get('/settings.env/systemEnv')

// 客服获取
export const apiServiceGet = () => request.get('/settings.service/getConfig')

// 客服设置
export const apiServiceSet = (params: any) => request.post('/settings.service/setConfig', params)
